
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import RouteTabsLeft from "@/views/Payment/components/RouteTabsLeft.vue";
import useMock from "@/utils/payment/useMock";
import { BUDataItem } from "@/views/User/ApprovalProcess/components/buTreeType";
import Data from "@/views/User/ApprovalProcess/components/mockBuTree";
import BUData from "@/views/User/ApprovalProcess/components/mockBu";
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  slots?: Record<string, string>;
  children?: TreeDataItem[];
}
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const { state, commit } = useStore();
    const getBUList = () => {
      if (state.approvalChain.BUListData.length === 0) {
        useMock<string>(BUData).then((res: any) => {
          commit("approvalChain/updateBUListData", res?.data?.data);
        });
      }
    };
    getBUList();

    const formatData = (data: BUDataItem[]) => {
      data.forEach((item) => {
        item.title = item.name;
        item.value = item.name;
        item.key = item.name;
        // delete item.id;
        // delete item.name;
        if (item.children && item.children.length) {
          formatData(item.children);
        }
      });
    };
    // const treeData = ref<TreeDataItem[]>([]);
    // 查询BU树
    if (state.approvalChain.BUTreeData.length === 0) {
      useMock<BUDataItem>(Data).then((res: any) => {
        const _data = res?.data?.data;
        formatData(_data);
        commit("approvalChain/updateBUTreeData", _data);
      });
    }

    return {};
  },
  components: {
    RouteTabsLeft,
  },
});
