import { BUDataItem } from "./buTreeType";

const Data: BUDataItem[] = [
  {
    id: "1",
    name: "MB",
    children: [
      {
        id: "1-1",
        name: "MB East",
      },
      {
        id: "1-2",
        name: "MB West",
      },
      {
        id: "1-3",
        name: "MB North",
      },
      {
        id: "1-4",
        name: "MB South",
      },
      {
        id: "1-5",
        name: "MB G Class",
      },
    ],
  },
  {
    id: "2",
    name: "EQ",
  },
  {
    id: "3",
    name: "AMG",
  },
  {
    id: "4",
    name: "Fleet",
  },
  {
    id: "5",
    name: "CPO",
    children: [
      {
        id: "5-1",
        name: "MB CPO",
      },
      {
        id: "5-2",
        name: "AMG CPO",
      },
    ],
  },
  {
    id: "6",
    name: "Company car",
  },
  {
    id: "7",
    name: "VAN",
    children: [
      {
        id: "7-1",
        name: "VAN Fleet",
      },
    ],
  },
  {
    id: "8",
    name: "DENZA",
    children: [
      {
        id: "8-1",
        name: "DENZA Fleet",
      },
    ],
  },
];
export default Data;
